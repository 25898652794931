import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';
import {
  faTwitter,
  faGoogle,
  faApple,
  faDiscord
} from '@fortawesome/free-brands-svg-icons';
import {
  faGlobe,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EsAboutPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>About</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/eversoul/categories/category_about.jpg"
            alt="About"
          />
        </div>
        <div className="page-details">
          <h1>About Eversoul</h1>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Collect beautiful Souls and enjoy a stunning visual RPG! Eversoul is
          an idle gacha game developed by Nine Ark and published by Kakao Games.
        </p>
        <SectionHeader title="Official description" />
        <h5>SUMMON UNIQUE SOULS</h5>
        <p>
          Summon myriads of beautifully crafted Souls from 6 different factions,
          each with their own exclusive skills and battle animations, and form
          your optimum Soul squad.
        </p>
        <h5>STRATEGIZE EPIC BATTLES</h5>
        <p>
          {' '}
          Master faction advantages, harness party buffs, and explore formations
          to show off your ultimate skills in fierce battles.
        </p>
        <h5>STUNNING ANIME RPG</h5>
        <p>
          Enjoy a stunning Visual RPG with graphics, animation, and artwork
          inspired by an eclectic array of anime while tuning in to beautiful
          audio designed to enrich your gameplay.
        </p>
        <h5>CREATE YOUR WORLD</h5>
        <p>
          Create and explore your own flavorful town with a variety of
          structures and decor, where you can roam free and interact with your
          Souls, take on missions day and night, or just slay the monsters
          lurking around the corner.
        </p>
        <h5>CHOOSE YOUR FATE</h5>
        <p>
          Interact with colorful Souls bursting with personality, but choose
          your answers wisely as they decide the fate of your relationship.
        </p>
        <h5>COLLECT AND LEVEL-UP</h5>
        <p>
          Collect unique Souls that you can level up and interact with to unlock
          exclusive stories.
        </p>
        <h5>RICH GAMEPLAY</h5>
        <p>
          Climb the ranks of the Arena leaderboard, face off against epic bosses
          with your guild mates, explore labyrinths, and go on dungeon runs for
          a complete PvE and PvP experience.
        </p>
        <h5>COMPELLING STORYLINE</h5>
        <p>
          {' '}
          A powerful narrative unfolds where you as the Savior are summoned
          across the multiverse to protect a parallel world from imminent
          danger.
        </p>
        <h5>AUTO BATTLES WITH IDLE MECHANICS</h5>
        <p>
          Hassle-free, effortless resource collection while you're idle, to earn
          as you play... or sleep!
        </p>
        <SectionHeader title="Promotional images" />
        <StaticImage
          src="../../images/eversoul/promo_1.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/eversoul/promo_2.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/eversoul/promo_3.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/eversoul/promo_4.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/eversoul/promo_5.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/eversoul/promo_6.webp"
          alt="Promotional image"
        />
        <SectionHeader title="Gameplay" />
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="wITFnMn2-Kk" />
          </Col>
        </Row>
        <SectionHeader title="Official links" />
        <Tabs
          defaultActiveKey="Global"
          transition={false}
          id="current-events-tabs"
          className="current-event-tabs"
        >
          <Tab eventKey="Global" title="Global">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://eversoul.playkakaogames.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faTwitter} /> Twitter
                    </div>
                    <div className="list-value">
                      <a
                        href="https://twitter.com/Eversoul_EN"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.kakaogames.eversoul"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/app/id1626247427"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">
                      <a
                        href="https://discord.com/invite/v5KTAj9rzv"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default EsAboutPage;

export const Head: React.FC = () => (
  <Seo
    title="About Eversoul | Eversoul | Prydwen Institute"
    description="Basic information about Eversoul."
    game="eversoul"
  />
);
